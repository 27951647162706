import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function ContactForm() {
	const [state, handleSubmit] = useForm("moqrkdnw");
	if (state.succeeded) {
		return (
			<div>
				{" "}
				<Form className="w-100" onSubmit={handleSubmit}>
					<Form.Group className="mb-3" controlId="name">
						<Form.Label className="fs-5">Name</Form.Label>
						<Form.Control
							required
							className="py-4 fs-5 text-secondary"
							name="name"
							type="text"
						/>
					</Form.Group>
					<ValidationError prefix="Name" field="name" errors={state.errors} />
					<Form.Group className="mb-3" controlId="email">
						<Form.Label className="fs-5">Email</Form.Label>
						<Form.Control
							className="py-4 fs-5 text-secondary"
							name="_replyto"
							type="email"
						/>
					</Form.Group>
					<ValidationError prefix="Email" field="email" errors={state.errors} />
					<Form.Group className="mb-3" controlId="telephone">
						<Form.Label className="fs-5">Telephone Number</Form.Label>
						<Form.Control
							className="py-4 fs-5 text-secondary"
							type="tel"
							name="telephone"
						/>
					</Form.Group>

					<ValidationError
						prefix="Telephone"
						field="telephone"
						errors={state.errors}
					/>
					<Form.Group className="mb-3" controlId="message">
						<Form.Label className="fs-5">Message</Form.Label>
						<Form.Control
							className="py-4 fs-5 text-secondary"
							name="message"
							as="textarea"
							rows={3}
						/>
					</Form.Group>

					<ValidationError
						prefix="Message"
						field="message"
						errors={state.errors}
					/>

					{/* <div
        class="g-recaptcha"
        data-sitekey=""
      ></div> */}
					<Button
						variant="primary"
						className=" py-3 mt-4 w-100 w-md-auto text-white px-4 "
						type="submit"
						id="contact-send-btn"
						disabled={state.submitting}
					>
						Send Message
					</Button>
				</Form>
				<div className="d-none">{(window.location = "/thank-you")}</div>
			</div>
		);
	}
	return (
		<Form className="w-100" onSubmit={handleSubmit}>
			<Form.Group className="mb-3" controlId="name">
				<Form.Label className="fs-5">Name</Form.Label>
				<Form.Control
					required
					className="py-4 fs-5 text-secondary"
					name="name"
					type="text"
				/>
			</Form.Group>
			<ValidationError prefix="Name" field="name" errors={state.errors} />
			<Form.Group className="mb-3" controlId="email">
				<Form.Label className="fs-5">Email</Form.Label>
				<Form.Control
					className="py-4 fs-5 text-secondary"
					name="_replyto"
					type="email"
				/>
			</Form.Group>
			<ValidationError prefix="Email" field="email" errors={state.errors} />
			<Form.Group className="mb-3" controlId="telephone">
				<Form.Label className="fs-5">Telephone Number</Form.Label>
				<Form.Control
					className="py-4 fs-5 text-secondary"
					type="tel"
					name="telephone"
				/>
			</Form.Group>

			<ValidationError
				prefix="Telephone"
				field="telephone"
				errors={state.errors}
			/>
			<Form.Group className="mb-3" controlId="message">
				<Form.Label className="fs-5">Message</Form.Label>
				<Form.Control
					className="py-4 fs-5 text-secondary"
					name="message"
					as="textarea"
					rows={3}
				/>
			</Form.Group>

			<ValidationError prefix="Message" field="message" errors={state.errors} />

			{/* <div
				class="g-recaptcha"
				data-sitekey="6LfMxmofAAAAAJrkK5OeFgJJ53LXPVQJPoNmyxYF"
			></div> */}
			<Button
				variant="primary"
				className=" py-3 mt-4 w-100 w-md-auto text-white px-4 "
				type="submit"
				id="contact-send-btn"
				disabled={state.submitting}
			>
				Send Message
			</Button>
		</Form>
	);
}
export default ContactForm;
