import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import ContactForm from "../components/contactForm";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import EventSection from "../components/event-section";
import SecondaryHero from "../components/secondary-hero";

const ContactUsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImgMain: wpMediaItem(title: { eq: "Home Hero Main" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Contact Us",
				item: {
					url: `${siteUrl}/contact-us`,
					id: `${siteUrl}/contact-us`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Contact Us | Friends of Eastbourne Hospital"
				description="Contact page for Friends of Eastbourne Hospital."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/contact-us`,
					title: "Contact Us | Friends of Eastbourne Hospital",
					description: "Contact page for Friends of Eastbourne Hospital.",
					images: [
						{
							url: `${data.heroImgMain?.localFile.publicURL}`,
							width: `${data.heroImgMain?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImgMain?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImgMain?.altText}`,
						},
					],
				}}
			/>
			<div style={{ overflow: "hidden" }}>
				<section className="pb-5">
					<SecondaryHero title="How to get in Touch" />
				</section>
				<section className="contact-us-div pt-lg-5">
					<Container>
						<Row>
							<Col className="text-center text-lg-start" lg={5}>
								<h2 className="text-primary fs-1">Address</h2>
								<p>
									Friends Office
									<br /> District General Hospital
									<br /> Kings Drive,
									<br /> Eastbourne,
									<br /> BN21 2UD
								</p>
								<p>
									Find us on{" "}
									<a
										href="https://www.google.co.uk/maps/place/Friends+of+Eastbourne+Hospital+Shop/@50.7860994,0.2668912,17z/data=!4m9!1m2!2m1!1sFriends+Office+General+District+Hospital+Kings+Drive,+Eastbourne,+BN21+2UD!3m5!1s0x47df71616b79b76f:0x97763c26a6413c65!8m2!3d50.7857986!4d0.26886!15sCkpGcmllbmRzIE9mZmljZSBHZW5lcmFsIERpc3RyaWN0IEhvc3BpdGFsIEtpbmdzIERyaXZlLCBFYXN0Ym91cm5lLCBCTjIxIDJVRCIDiAEBWkoiSGZyaWVuZHMgb2ZmaWNlIGdlbmVyYWwgZGlzdHJpY3QgaG9zcGl0YWwga2luZ3MgZHJpdmUgZWFzdGJvdXJuZSBibjIxIDJ1ZJIBDWdlbmVyYWxfc3RvcmU"
										target="_blank"
										rel="noreferrer"
									>
										Google Maps
									</a>
								</p>
								<h2 className="text-primary mt-5 fs-1">Office telephone</h2>
								<a className="text-decoration-none" href="tel:+441323749503">
									01323 749 503
								</a>
								<h2 className="text-primary mt-5 fs-1">Shop telephone</h2>
								<a className="text-decoration-none" href="tel:+441323647188">
									01323 647 188
								</a>
								<h2 className="text-primary mt-5 fs-1">Volunteers office</h2>
								<p>
									<a className="text-decoration-none" href="tel:+443001314500">
										0300 131 4500
									</a>{" "}
									extension 771615
								</p>
								<h2 className="text-primary mt-5 fs-1">Office opening times</h2>
								<p>
									Mon: 09:00 - 14:00
									<br />
									Tue: 09:00 - 14:00
									<br />
									Wed: 09:00 - 14:00
									<br />
									Thu: 09:00 - 14:00
									<br />
									Fri: 09:00 - 14:00
								</p>
							</Col>
							<Col lg={7}>
								<ContactForm />
							</Col>
						</Row>
						<Row className="mt-lg-5 text-center text-lg-start">
							<Col>
								<h2 className="text-primary mt-5 display-4 pb-5">
									Useful contacts
								</h2>
							</Col>
						</Row>
						<Row className="pb-5 pb-lg-7 text-center text-lg-start">
							<Col lg={6}>
								<h3 className="text-primary fs-1">Main DGH Telephone</h3>
								<a className="text-decoration-none" href="tel:+443001314500">
									0300 131 4500
								</a>
							</Col>
							<Col lg={6}>
								<h3 className="text-primary mt-5 mt-lg-0 fs-1">
									DGH Appointments
								</h3>
								<a className="text-decoration-none" href="tel:+443001314600">
									0300 131 4600
								</a>
							</Col>
						</Row>
					</Container>
				</section>
				<section>
					<EventSection />
				</section>
			</div>
		</Layout>
	);
};

export default ContactUsPage;
